import { formatData } from "@/app/util";
import { request } from "@/request";

// https://api.scorechain.com/v1/blockchains/BSC/addresses/0xd1d6bf74282782b0b3eb1413c901d6ecf02e8e28
export function getOmData(boinType: any, hash: any) {
  return request.get<any>(`/v1/blockchains/${boinType}/addresses/${hash}`, {});
}
// https://api.scorechain.com/v1/lists?type=CUSTOMER_PORTFOLIO&offset=0&limit=20
export function getCustomerList() {
  return request.get<any>(`/v1/lists`, {
    type: "CUSTOMER_PORTFOLIO",
    offset: 0,
    limit: 20,
  });
}

// https://api.scorechain.com/v1/lists/55784/addresses
export function addHashToCustomer(id:any,params: any) {
    return request.post<any>(`/v1/lists/${id}/addresses`, params);
  }
// 
// https://api.scorechain.com/v1/lists/55784/addresses/342154
// 
export function deleteHashFromCustomer(id:any,addressID: any) {
    return request.delete<any>(`/v1/lists/${id}/addresses/${addressID}`, {});
  }