import localData from "./localData"

// 有道翻译模块
const appKey = '56fea8707a9bb74c';
const key = 'f3j8PnfYuGaoGAEJzW1mTM5zAOzqgUdi';//注意：暴露appSecret，有被盗用造成损失的风险
var to = 'zh-CHS';
var from = 'en';

const localDataKey = "translate_words"

let localwords = localData.GetV(localDataKey)
let translates: any = {}
if (localwords) {
  translates = JSON.parse(localwords)
}


function request(query: string) {
  return new Promise<string>((resolve, reject) => {
    const date = new Date()
    const salt = date.getTime();
    const curtime = Math.round(date.getTime()/1000);
    const str1 = appKey + truncate(query) + salt + curtime + key;
    const vocabId =  '您的用户词表ID';
    const sign = CryptoJS.SHA256(str1).toString(CryptoJS.enc.Hex);
  
    $.ajax({
      url: 'https://openapi.youdao.com/api',
      type: 'post',
      dataType: 'jsonp',
      data: {
          q: query,
          appKey: appKey,
          salt: salt,
          from: from,
          to: to,
          sign: sign,
          signType: "v3",
          curtime: curtime,
          vocabId: vocabId,
      },
      success: function (data) {
          resolve(data && data.translation && data.translation[0])
      } 
    });
  })
}

// 先从本地查找，没有再进行翻译请求
export default async function Translate (input: string) {
  // words
  // const query = input.replace(/[^a-z0-9 +-]/gi, ' ');
  // if (query in translates) return translates[query]
  // const content = await request(query)
  // if (content !== "") {
  //   translates[query] = content
  //   localData.SetV(localDataKey, JSON.stringify(translates))
  // }
  // return content
  return input
}


function truncate(q: string){
  var len = q.length;
  if(len<=20) return q;
  return q.substring(0, 10) + len + q.substring(len-10, len);
}