import { formatData } from "@/app/util";
import { request } from "@/request";

// NotificationsList列表
export function ScenariosAlerts(params: any) {
  Object.keys(params).map(key =>{
    if(params[key] === ''){
      delete params[key];
    }
  })
  return request.get<any>(`/v1/scenarios/alerts`, params);
}

export function PatchScenariosAlerts(id: any,alertId: any,params: any) {
  return request.patch<any>(`/v1/scenarios/${id}/alerts/${alertId}`, params);
}

// 
// https://api.scorechain.com/v1/scenarios/bde0c02a-1ad7-47ca-849b-e80c8543d01e/alerts/8ae3bd9d-40d7-4d5b-a7ef-8a7ec741eaae
// 
// https://api.scorechain.com/v1/scenarios/bde0c02a-1ad7-47ca-849b-e80c8543d01e/alerts/8ae3bd9d-40d7-4d5b-a7ef-8a7ec741eaae/history
export function getAlertsData(scenarioId: any, alertId: any) {
  return request.get<any>(`/v1/scenarios/${scenarioId}/alerts/${alertId}`, {});
}
export function getAlertsDataHistory(scenarioId: any, alertId: any) {
  return request.get<any>(`/v1/scenarios/${scenarioId}/alerts/${alertId}/history`, {});
}