import styled from "./address.module.css";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { GetAddress, selectAddress } from "@/features/entity/entitySlice";
import { t } from "i18next";

import { Button, Col, Form, Input, Row, Space, Table, Tag } from "antd";
import dayjs from "dayjs";
import type { ColumnsType, TableProps } from "antd/es/table";
import { initAddress, selectAddressInfo, selectLoading, selectAnalysisType, GetAnalysisData, showAssets, AddressType, selectChoosMetadata, changeChoosMetadata, selectSymbol, selectBlockChain, blockchainTranslate } from "@/features/address/addressSlice";
import { Link } from "react-router-dom";
export default function EntityAddress(props: { onClose: Function }) {
  const addressList = useAppSelector(selectAddress);
  const blockchains = useAppSelector(selectBlockChain);
  const [page, setPage] = useState(0);

  const dispatch = useAppDispatch();

  if (!addressList || !blockchains) return null;
  const columns: ColumnsType<any> = [
    {
      title: t("addressText"),
      dataIndex: "addressText",
      key: "addressText",
      width: 400,
      render: (text, i) => {
        return <>{i}</>;
      },
    },
    {
      title: t("handleText"),
      dataIndex: "action",
      width: 400,
      fixed: true,
      key: "action",
      render: (text, i) => {
        return (
          <Link
            style={{ color: "#0080ff" }}
            to={`/address/1/${addressList.blockchain}/${i}`}
          >
            More details
          </Link>
        );
      },
    },
  ];

  return (
    <Modal
      show={true}
      onHide={() => props.onClose()}
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div className={styled.addresswintitle}>
            <img
              src={`https://app.scorechain.com/images/cryptoicons/${addressList.blockchain}.svg`}
              alt=""
            />
            <span>
              {addressList?.entityName} -{" "}
              {/* {blockchainTranslate(addressList?.blockchain)}{" "} */}
              {blockchains[addressList?.blockchain].mainAssetSymbol}{" "}
              {t("entities.wallet")}
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{height:'710px'}}>
        <div className={styled.addresswinbody}>
          <p className={styled.adddresstopic}>
          Since {addressList?.blockchain} is an account-based blockchain, there is no aggregated view. Please select a specific address.
          </p>
          <div className={styled.addresstable}>
            <Table
              scroll={{ x: "1000" }}
              columns={columns}
              dataSource={addressList?.hashList}
              pagination={{
                showSizeChanger: false,
                pageSize: 10,
              }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
