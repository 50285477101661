import { useTranslation } from "react-i18next";
import "./assetsList.css";
import { CoinElm } from "./assetsList";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  GetAddress,
  reverseWallets,
  selectWallets,
} from "../entity/entitySlice";
import { useState } from "react";
import { blockchainTranslate, hasWallet } from "./addressSlice";
import AddressWin from "@/views/Entity/Address";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";

import { Button, Col, Form, Input, Row, Space, Modal, Table, Tag } from "antd";
import dayjs from "dayjs";
import type { ColumnsType, TableProps } from "antd/es/table";
import { Link } from "react-router-dom";
export default function WalletList() {
  const { t } = useTranslation();
  const { name, id } = useParams();
  const wallets = useAppSelector(selectWallets);
  const [showAddress, setShowAddress] = useState(false);
  const dispatch = useAppDispatch();
  if (!wallets || !name || !id) return null;
  const columns: ColumnsType<any> = [
    {
      title: 'Wallet',
      dataIndex: "country",
      key: "country",
      width: 150,
      fixed: true,
      sortDirections: ["ascend", "descend"],
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        return a.country.localeCompare(b.country);
      },
      render: (text, i) => {
        return (
          <CoinElm
            coinSrc={`https://app.scorechain.com/images/cryptoicons/${i.blockchain}.svg`}
            coinName={blockchainTranslate(i.blockchain)}
          />
        );
      },
    },
    {
      title:'Addresses',
      dataIndex: "addressCount",
      width: 400,
      fixed: true,
      key: "addressCount",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: 150,
      render: (text, i) => {
        let selectData = i;
        if (hasWallet(selectData.blockchain)) {
          return (
            <Link
              style={{ color: "#0080ff" }}
              to={`/address/2/${selectData?.blockchain}/${selectData.referenceAddress}`}
            >
              More details
            </Link>
          );
        } else {
          return (
            <div
              style={{ color: "#0080ff" ,cursor: "pointer" }}
              onClick={() => {
                setShowAddress(true);
                dispatch(
                  GetAddress({
                    entityId: parseInt(id),
                    blockchain: selectData.blockchain,
                    offset: 0,
                    limit: 10,
                    entityName: name,
                  })
                );
              }}
            >
              More details
            </div>
          );
        }
      },
    },
  ];

  return (
    <>
      <div className="assets-container">
        <div>
          <p className="assets-title">Supported Wallets</p>
          <Table
            scroll={{ x: "1000" }}
            columns={columns}
            dataSource={wallets}
            pagination={{
              showSizeChanger: false,
              pageSize: 4,
            }}
          />
        </div>
      </div>
      {showAddress ? (
        <AddressWin onClose={() => setShowAddress(false)} />
      ) : null}
    </>
  );
}
