import { useTranslation } from "react-i18next"
import bottomButton from '@/assets/bottom.png'
import rightButton from '@/assets/toRight.png'
import "./tradingList.css"
import { useState } from "react"
import React from "react"
import { CSSTransition } from 'react-transition-group'
import { useAppSelector } from "@/app/hooks"
import { selectTransaction } from "../transaction/transactionSlice"
import { Link } from "react-router-dom"


export default function TradingList () {
  const { t } = useTranslation()

  const [showList, setShowList] = useState(false)
  const nodeRef = React.useRef(null)

  const transaction = useAppSelector(selectTransaction);
  
  if (!transaction) return null

  return (
    <div className="trading-list-wrap">
      <p className="title">{t("transaction.title")}</p>

      <div className={`trading-list-item originator ${showList ? "showlist":""}`}>
        <p className="name">{t("transaction.originator")}</p>
        <div className="address-selector normal-click" onClick={() => setShowList(!showList)}>
          <p className="content">{t("transaction.addressList")}&nbsp;&nbsp;({transaction.signers.length}{t("transaction.addressDescribe")})</p>
          <img src={bottomButton} alt="" />

          <CSSTransition
            in={showList}
            timeout={300}
            classNames="alert"
            nodeRef={nodeRef}
            unmountOnExit>
            <div ref={nodeRef} className="address-list">
              {transaction.signers.map((i, k) => <Link to={`/address/1/${transaction.blockchain}/${i}`} key={"pk-"+k}><p>{i}</p></Link>)}
            </div>
          </CSSTransition>
        </div>
      </div> 

      <div className="trading-list-item handle">
        <p className="name">{t("handleText")}</p>
        <div>
          {
            transaction.operations.map((operationItem, opK) => (
              <div className="handle-wrap" key={"op"+opK}>
                <div className="handle-title">
                  <img src={operationItem.icon} alt="" />
                  <p className="content active">{operationItem.amount} {operationItem.symbol}<span>（{operationItem.amountUsd} USD）</span></p>
                </div>
                <div className="io-container">
                  <div className="io input">
                    <p>From ({operationItem.source.length}  {operationItem.source.length>1?'inputs':'input'})</p>
                    { operationItem.source.map((sourceItem, sourceKey) => 
                      <Link to={`/address/1/${transaction.blockchain}/${sourceItem.address}`} key={sourceKey}>
                        <p className="active">
                          {sourceItem.address}<span>（{sourceItem.amount} {operationItem.symbol}）</span>
                        </p>
                      </Link>
                    )}
                      
                  </div>
                  <img src={rightButton} alt="" />
                  <div className="io output">
                    <p>To ({operationItem.destinations.length}  {operationItem.destinations.length>1?'outputs':'output'})</p>
                    { operationItem.destinations.map((destinationItem, desKey) => 
                      <Link to={`/address/1/${transaction.blockchain}/${destinationItem.address}`} key={desKey}>
                        <p className="active">
                          {destinationItem.address}<span>（{destinationItem.amount} {operationItem.symbol}）</span>
                        </p>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>

      <div className="trading-list-item total-cost">
        <p className="name">{t("transaction.cost")}</p>
        <div>
          <p className="content">{transaction.amount}&nbsp;{transaction.unit}<span>（{transaction.amountUsd} USD）</span></p>
        </div>
      </div>

      <div className="trading-list-item status">
        <p className="name">{t("status")}</p> 
        <div className="address-selector">
          {/* scoreChainSuccess */}
          <p className="content">{t("transaction." + transaction.scoreChainSuccess.toLowerCase())}</p>
        </div>
      </div>
    </div>
  )
}