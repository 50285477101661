/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import Header from "@/features/user/Header";
import Navigation from "@/features/common/navigation";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import "./notificationsListDetail.css";

import {
  Col,
  Form,
  Input,
  Row,
  Modal,
  Table,
  Select,
  Dropdown,
  Tooltip,
} from "antd";
import dayjs from "dayjs";

import {
  getAlertsData,
  getAlertsDataHistory,
  PatchScenariosAlerts,
} from "@/features/NotificationsList/NotificationsListApi";
import { riskLevelText, statusText, actionStatusText } from "./config";

const { Option } = Select;
const { TextArea } = Input;

export default function AlertPage() {
  const navigate = useNavigate();
  const { scenarioId, alertId } = useParams();
  const [data, setData] = useState<any>(null);
  const [list, setList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(1);
  const [modalData, setModalData] = useState<any>({});
  const [modalForm] = Form.useForm();
  document.title = "Alert Notifications Details";
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 200,
      fixed: true,
      // sortDirections:'ascend',
      // sorter: (a: number, b: number) => a - b,
      render: (text: any) => {
        return <>{dayjs(text * 1000).format("YYYY-MM-DD HH:mm")}</>;
      },
    },
    {
      title: "User Name",
      dataIndex: "author",
      width: 200,
      fixed: true,
      key: "author",
      render: (text: any, data: any) => {
        let name = "N/A";
        if (data?.author?.firstname || data?.author?.lastname) {
          name = data?.author?.firstname + data?.author?.lastname;
        }
        return <div>{name}</div>;
      },
    },
    {
      title: "User Role",
      dataIndex: "role",
      key: "role",
      width: 150,
      render: (text: any, data: any) => {
        let name = "N/A";
        if (data?.author?.role) {
          name = data?.author?.role;
        }
        return <div>{name}</div>;
      },
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: 150,
      render: (text: any, data: any) => {
        let name = "N/A";
        if (data?.changes.length > 0) {
          name = data?.changes[0].attribute;
        }
        if (name === "status") {
          name = "Status changed";
        }
        if (name === "actionStatus") {
          name = "Action changed";
        }
        return <div>{name}</div>;
      },
    },
    {
      title: "Action Details",
      dataIndex: "Action details",
      key: "Action details",
      width: 300,
      render: (text: any, data: any) => {
        let name1 = null;
        let name2 = null;
        let key = "";
        if (data?.changes.length > 0) {
          key = data?.changes[0].attribute;
        }
        let newData = data?.changes[0];
        if (key === "status") {
          statusText.map((status) => {
            if (status.key === newData?.previous) {
              name1 = status.text;
            }
          });
          statusText.map((status) => {
            if (status.key === newData?.new) {
              name2 = status.text;
            }
          });
        }
        if (key === "actionStatus") {
          actionStatusText.map((status) => {
            if (status.key === newData?.previous) {
              name1 = status.text;
            }
          });
          actionStatusText.map((status) => {
            if (status.key === newData?.new) {
              name2 = status.text;
            }
          });
        }
        return (
          <div>
            {!name1 && !name2 ? (
              "N/A"
            ) : (
              <>
                <span>From</span>
                <span style={{ fontWeight: 800, padding: "0 4px" }}>
                  {" "}
                  {name1 || "N/A"}
                </span>
                <span>To</span>
                <span style={{ fontWeight: 800, padding: "0 4px" }}>
                  {" "}
                  {name2 || "N/A"}
                </span>
              </>
            )}
          </div>
        );
      },
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      width: 200,
      render: (text: any) => {
        return (
          <Tooltip placement="topLeft" title={text || "N/A"}>
            {text || "N/A"}
          </Tooltip>
        );
      },
    },
  ];
  useEffect(() => {
    if (scenarioId && alertId) {
      getData();
      getDataHistory();
    }
  }, [scenarioId, alertId]);
  const getData = async () => {
    let res = await getAlertsData(scenarioId, alertId);
    setData(res);
  };
  const getDataHistory = async () => {
    let res = await getAlertsDataHistory(scenarioId, alertId);
    setList(res?.items);
  };
  const handleOk = async () => {
    modalForm.validateFields().then(async (values) => {
      await PatchScenariosAlerts(
        modalData?.scenario?.id,
        modalData?.id,
        values
      );
      modalForm.resetFields();
      setIsModalOpen(false);
      getData();
      getDataHistory();
    });
  };
  const renderStatusBtn = () => {
    let label = null;
    statusText.map((status) => {
      if (status.key === data?.status) {
        label = status.label;
      }
    });
    return (
      <Dropdown
        key={data?.status}
        menu={{
          items: statusText,
          onClick: (e) => {
            if (e.key === data?.status) {
              return;
            }
            let newData = {
              ...data,
              status: e.key,
            };
            setModalType(1);
            setModalData(newData);
            setIsModalOpen(true);
            modalForm.setFieldsValue(newData);
          },
        }}
      >
        <a onClick={(e) => e.preventDefault()} key={data?.status}>
          {label}
        </a>
      </Dropdown>
    );
  };
  const renderActionStatusBtn = () => {
    let label = null;
    actionStatusText.map((status) => {
      if (status.key === data?.actionStatus) {
        label = status.label;
      }
      if (data?.actionStatus === null && status.key === "EMPTY") {
        label = status.label;
      }
    });
    return (
      <Dropdown
        key={data?.actionStatus}
        menu={{
          items: actionStatusText,
          onClick: (e) => {
            if (
              e.key === data?.actionStatus ||
              (data?.actionStatus === null && e.key === "EMPTY")
            ) {
              return;
            }
            let newData = {
              ...data,
              actionStatus: e.key,
            };
            setModalType(2);
            setModalData(newData);
            setIsModalOpen(true);
            modalForm.setFieldsValue(newData);
          },
        }}
      >
        <a onClick={(e) => e.preventDefault()} key={data?.actionStatus}>
          {label}
        </a>
      </Dropdown>
    );
  };
  return (
    <>
      <Header />
      <Modal
        title={modalType === 1 ? "Change Status" : "Add Action to Alert"}
        open={isModalOpen}
        onOk={handleOk}
        centered
        onCancel={() => {
          modalForm.resetFields();
          setIsModalOpen(false);
        }}
      >
        <p>
          {modalType === 1
            ? "You can add a justification for the new status. This will be included in the audit trail of the alert."
            : "You can add a justification for the new action. This will be included in the audit trail of the alert."}
        </p>
        <Form
          form={modalForm}
          name="modal_form"
          style={{
            maxWidth: "none",
            padding: 24,
          }}
          layout="vertical"
        >
          <Row gutter={24}>
            {modalType === 1 && (
              <Col span={24}>
                <Form.Item
                  name={`status`}
                  label={`New Status:`}
                  rules={[{ required: true }]}
                >
                  <Select disabled>
                    {statusText.map((item) => {
                      return <Option value={item.key}>{item.label}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>
            )}

            {modalForm.getFieldValue("status") === "RESOLVED" &&
              modalType === 1 && (
                <Col span={24}>
                  <div style={{ textAlign: "center" }}>
                    The action status is required when changing an alert status
                    into Resolved
                  </div>
                </Col>
              )}
            {(modalForm.getFieldValue("status") === "RESOLVED" ||
              modalType === 2) && (
              <Col span={24}>
                <Form.Item
                  name={`actionStatus`}
                  label={`Action Status:`}
                  rules={[{ required: true }]}
                >
                  <Select>
                    {actionStatusText.map((item) => {
                      return <Option value={item.key}>{item.label}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>
            )}

            <Col span={24}>
              <Form.Item
                name={`comment`}
                label="Justification:"
                rules={[{ required: true }]}
                help="Justification is required"
              >
                <TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <div className="alert-page-container body-container">
        <Navigation id={data?.scenario?.name} />
        <div className="alert-detail-title">
          Scenario {data?.scenario?.name} - Alert for {data?.addressHash}
        </div>
        <div className="alert-detail-top-container mb24">
          <div className="alert-detail-top-left-main">
            <div className="alert-detail-top-title mb24">Alert Information</div>
            <div className="alert-detail-top-item mb14">
              <div className="alert-detail-top-item-title ">
                Alert Scenario:
              </div>
              <div className="alert-detail-top-item-content ">
                {data?.scenario?.name}
              </div>
            </div>
            <div className="alert-detail-top-item mb14">
              <div className="alert-detail-top-item-title ">Risk Level:</div>
              <div className="alert-detail-top-item-content ">
                {riskLevelText.map((item) => {
                  if (item.key === data?.riskLevel) {
                    return item.label;
                  }
                })}
              </div>
            </div>
            <div className="alert-detail-top-item mb14">
              <div className="alert-detail-top-item-title ">
                Item Monitored:
              </div>
              <div className="alert-detail-top-item-content ">
                <div
                  onClick={() => {
                    let item = data?.item?.link;
                    let arr = item.split("/");
                    navigate(
                      `/address/1/${arr[2].toUpperCase()}/${data?.addressHash}`
                    );
                  }}
                >
                  <span style={{ color: "#0080FF" }}>{data?.addressHash}</span>
                </div>
              </div>
            </div>
            <div className="alert-detail-top-item mb14">
              <div className="alert-detail-top-item-title ">
                Date Triggered:
              </div>
              <div className="alert-detail-top-item-content ">
                {dayjs(data?.date * 1000).format("YYYY-MM-DD HH:mm:ss")}
              </div>
            </div>
          </div>
          <div className="alert-detail-top-right-main">
            <div className="alert-detail-top-title mb24">Alert Management</div>
            <div className="alert-detail-top-item mb18">
              <div className="alert-detail-top-item-title ">Status:</div>
              <div className="alert-detail-top-item-content ">
                {renderStatusBtn()} on{" "}
                {dayjs(data?.date * 1000).format("YYYY-MM-DD HH:mm:ss")}
              </div>
            </div>
            <div className="alert-detail-top-item mb18">
              <div className="alert-detail-top-item-title ">Action Status:</div>
              <div className="alert-detail-top-item-content ">
                {renderActionStatusBtn()}
              </div>
            </div>
          </div>
        </div>
        <div className="container-item list-container mb24">
          <div className="alert-detail-middle-content">
            <div className="alert-detail-top-title mb24">Alert Details</div>
            <div
              className="alert-detail-middle-desc"
              style={{ fontSize: "14px" }}
            >
              This alert has been triggered on{" "}
              <span style={{ fontWeight: 800 }}>
                {dayjs(data?.date * 1000).format("YYYY-MM-DD HH:mm:ss")}
              </span>{" "}
              AND The wallet{" "}
              <span
                style={{ color: "#0080FF", cursor: "pointer" }}
                onClick={() => {
                  let item = data?.item?.link;
                  let arr = item.split("/");
                  navigate(
                    `/address/1/${arr[2].toUpperCase()}/${data?.addressHash}`
                  );
                }}
              >
                {data?.addressHash}
              </span>{" "}
              has been involved with a transaction hash{" "}
              <span
                style={{ color: "#0080FF", cursor: "pointer" }}
                onClick={() => {
                  let item = data?.item?.link;
                  let arr = item.split("/");
                  navigate(
                    `/trading/${arr[2].toUpperCase()}/${data?.transactionHash}`
                  );
                }}
              >
                {data?.transactionHash}
              </span>
            </div>
          </div>
        </div>
        <div
          className="container-item list-container mb24"
          style={{ padding: 0 }}
        >
          <div className="alert-detail-top-title alert-detail-list-padding">
            Audit Trail
          </div>
          <div>
            <Table
              scroll={{ x: "1000" }}
              columns={columns}
              dataSource={list}
              pagination={{
                showSizeChanger: false,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
