import { useTranslation } from "react-i18next";
import "./riskAssessment.css";
import securityIcon from "@/assets/security.png";
import warningIcon from "@/assets/warning.png";
import { useAppSelector } from "@/app/hooks";
import { selectEntityInfo } from "../entity/entitySlice";

export default function ValidationRules() {
  const { t } = useTranslation();

  const entityInfo = useAppSelector(selectEntityInfo);
  if (!entityInfo) return null;

  const policies = entityInfo.policies;

  return (
    <div className="risk-assessment-container">
      <p className="title">Onboarding Policies</p>

      <ul>
        {policies.isAskingPersonalInformation !== null && (
          <>
            <li>
              <p className="describe">KYC Policies</p>
              <img
                src={
                  policies.isAskingPersonalInformation
                    ? securityIcon
                    : warningIcon
                }
                alt=""
              />
              <span>
                {policies.isAskingPersonalInformation
                  ? "This VASP does not allow users to access its platform without providing know-your-customer (KYC) information"
                  : "This VASP allow users to access its platform without providing know-your-customer (KYC) information"}
              </span>
            </li>
          </>
        )}

        {policies.isApplyingSanctions !== null && (
          <>
            <li>
              <p className="describe">Sanctions</p>
              <img
                src={policies.isApplyingSanctions ? securityIcon : warningIcon}
                alt=""
              />
              <span>
                {policies.isApplyingSanctions
                  ? "This VASP has sanction policy including sanction screening procedures"
                  : "This VASP has no sanction policy including sanction screening procedures"}
              </span>
            </li>
          </>
        )}

        {policies.hasDueDiligence !== null && (
          <>
            <li>
              <p className="describe">Due Diligence</p>
              <img
                src={policies.hasDueDiligence ? securityIcon : warningIcon}
                alt=""
              />
              <span>
                {policies.hasDueDiligence
                  ? "This VASP has identification and verification procedures"
                  : "This VASP does not have identification and verification procedures"}
              </span>
            </li>
          </>
        )}
        {policies.hasAdvancedDueDiligence !== null && (
          <>
            <li>
              <p className="describe">Enhanced Due Diligence</p>
              <img
                src={
                  policies.hasAdvancedDueDiligence ? securityIcon : warningIcon
                }
                alt=""
              />
              <span>
                {policies.hasAdvancedDueDiligence
                  ? "This VASP follows additional verification and reporting procedures"
                  : "This VASP doesn't follow additional verification and reporting procedures"}
              </span>
            </li>
          </>
        )}

        {policies.hasComplianceOfficer !== null && (
          <>
            <li>
              <p className="describe">Compliance Function</p>
              <img
                src={policies.hasComplianceOfficer ? securityIcon : warningIcon}
                alt=""
              />
              <span>
                {policies.hasComplianceOfficer
                  ? "This VASP has an appointed Compliance Officer"
                  : "This VASP does not have an appointed Compliance Officer"}
              </span>
            </li>
          </>
        )}
      </ul>
    </div>
  );
}
