/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { GetColor } from "@/features/common/dashboard";

import { useTranslation } from "react-i18next";
import Header from "@/features/user/Header";
import Navigation from "@/features/common/navigation";
import { Button, Col, Form, Input, Row, Space, Modal, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import "./MyList.css";
import {
  getIdList,
  getListsAdress,
  deleteItem,
} from "@/features/MyList/MyList";
import {
  selectBlockChain,
  blockchainTranslate,
} from "@/features/address/addressSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import deleteIcon from "@/assets/delete.png";
import type { ColumnsType, TableProps } from "antd/es/table";

export function ColorElm(props: { score: number }) {
  return (
    <span
      style={{
        width: "45px",
        height: "16px",
        textAlign: "center",
        lineHeight: "16px",
        fontSize: "10px",
        fontWeight: "bold",
        display: "inline-block",
        borderRadius: "24px",
        color: "#ffffff",
        backgroundColor: GetColor(props.score),
      }}
    >
      {props.score}
    </span>
  );
}
function NotificationsList() {
  document.title = "My List";
  const { t } = useTranslation();
  const [Id, setId] = useState(0);
  const blockchains = useAppSelector(selectBlockChain);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState<any>({});
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState<any>([]);
  const columns: ColumnsType<any> = [
    {
      title: "Date",
      dataIndex: "addedDate",
      key: "addedDate",
      width: 200,
      fixed: true,
      sortDirections: ["ascend", "descend"],
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        return a.addedDate - b.addedDate;
      },
      render: (text: any) => {
        return <>{dayjs(text * 1000).format("YYYY-MM-DD HH:mm")}</>;
      },
    },
    {
      title: "Address",
      dataIndex: "hash",
      width: 400,
      fixed: true,
      key: "hash",
      render: (text: any, record: any) => {
        return (
          <Link to={`/address/1/${record?.blockchain}/${text}`}>{text}</Link>
        );
      },
    },
    {
      title: "Blockchain",
      dataIndex: "blockchain",
      key: "blockchain",
      width: 150,
      render: (text: any) => {
        if (!blockchains) return <></>;
        let url = blockchains[text].icon
          ? `https://app.scorechain.com/images/cryptoicons/${blockchains[text].icon}`
          : "";
        return (
          <div style={{ display: "flex" }}>
            <img
              src={url}
              alt=""
              style={{ width: "15px", marginRight: "8px" }}
            />
            {blockchains[text].name}
          </div>
        );
      },
    },
    {
      title: "Entity Name",
      dataIndex: "info",
      key: "info",
      width: 150,
      render: (text: any) => {
        return <>{text?.entityName || "N/A"}</>;
      },
    },
    {
      title: "Type",
      dataIndex: "info",
      key: "info",
      width: 150,
      render: (text: any) => {
        return <>{text?.entityType || "N/A"}</>;
      },
    },
    {
      title: "Balance",
      dataIndex: "info",
      key: "info",
      width: 150,
      render: (text: any, data: any) => {
        if (!blockchains) return <>0</>;
        return (
          <>
            {text?.mainAsset?.balance || "0"}{" "}
            {blockchains[data?.blockchain].mainAssetSymbol}
          </>
        );
      },
    },
    {
      title: "Value",
      dataIndex: "info",
      key: "info",
      width: 150,
      render: (text: any) => {
        return <>{text?.mainAsset?.balanceUsd || "0"} USD</>;
      },
    },
    {
      title: "Transactions",
      dataIndex: "numberOfTransactions",
      key: "numberOfTransactions",
      width: 150,
      render: (text: any) => {
        return <>{text || "0"}</>;
      },
    },
    {
      title: "Incoming Risk Score",
      dataIndex: "info",
      key: "info",
      width: 200,
      render: (text: any) => {
        if (!text || !text?.mainAsset?.score) return <>N/A</>;
        return <ColorElm score={text?.mainAsset?.score} />;
      },
    },
    {
      title: "First Activity",
      dataIndex: "info",
      key: "info",
      width: 150,
      render: (text: any) => {
        let str = "N/A";
        if (text?.firstActivity) {
          str = dayjs(text?.firstActivity * 1000).format("YYYY-MM-DD HH:mm");
        }
        return <>{str}</>;
      },
    },
    {
      title: "Last Activity",
      dataIndex: "info",
      key: "info",
      width: 180,
      render: (text: any) => {
        let str = "N/A";
        if (text?.lastActivity) {
          str = dayjs(text?.lastActivity * 1000).format("YYYY-MM-DD HH:mm");
        }
        return <>{str}</>;
      },
    },
    // {
    //   title: "Risk indicators",
    //   dataIndex: "info",
    //   key: "info",
    //   width: 150,
    //   render: (text: any) => {
    //     let str = "No risk detected";
    //     if (text?.mainAsset?.riskIndicators.length > 0) {
    //       str = text?.mainAsset?.riskIndicators.toString();
    //     }
    //     return <>{str}</>;
    //   },
    // },
    {
      title: "Action",
      width: 80,
      render: (text: any, data: any) => {
        return (
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={(e) => {
              setModalData(data);
              setIsModalOpen(true);
            }}
          >
            <img src={deleteIcon} alt="" style={{ width: "20px" }} />
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    getIds();
  });
  useEffect(() => {
    if (Id) {
      getLists();
    }
  }, [Id]);
  const getIds = async () => {
    let res = await getIdList({
      type: "CUSTOMER_PORTFOLIO",
      offset: 0,
      limit: 1,
      severity: "UNDEFINED_RISK",
    });
    setId(res?.items?.[0]?.id);
  };
  const getLists = async () => {
    let info = await getListsAdress(Id, {});
    setData(info.items);
  };
  const fuzzyMatch = (data: any[], searchString: string) => {
    return data.filter((item: { [x: string]: any }) => {
      for (let key of Object.keys(item)) {
        const value = item[key];
        if (
          typeof value === "string" &&
          value.toLowerCase().includes(searchString.toLowerCase())
        ) {
          return true;
        } else if (typeof value === "object" && value !== null) {
          if (fuzzyMatch([value], searchString).length > 0) {
            return true;
          }
        }
      }
      return false;
    });
  };

  const onFinish = (values: any) => {
    let search = values?.search;
    if (!search) return;
    setSearchData(fuzzyMatch(data, search));
  };
  const handleOk = async () => {
    await deleteItem(Id, modalData.id);
    getLists();
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Header />
      <div className="alert-page-container body-container">
        <Navigation id={"My List"} />
        <div className="MyListPage-title">Ongoing Monitoring List</div>
        <div className="MyListPage-desc">Including {data.length} addresses</div>
        <div className="container-item list-container" style={{ padding: 0 }}>
          <Modal
            title="Are you sure?"
            open={isModalOpen}
            onOk={handleOk}
            centered
            onCancel={handleCancel}
          >
            <p>Click on delete to remove this address form this list.</p>
          </Modal>
          <div className="MyListPage-search-container">
            <Form
              form={form}
              name="advanced_search"
              style={{
                maxWidth: "none",
                padding: 24,
              }}
              onFinish={onFinish}
            >
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item name={`search`} label={`Search`}>
                    <Input placeholder="Search" />
                  </Form.Item>
                </Col>
                <Col span={8} offset={8}>
                  <div style={{ textAlign: "right" }}>
                    <Space size="small">
                      <Button type="primary" htmlType="submit">
                        Search
                      </Button>
                      <Button
                        onClick={() => {
                          setSearchData([]);
                          form.resetFields();
                        }}
                      >
                        Clear
                      </Button>
                    </Space>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
          <Table
            scroll={{ x: "1000" }}
            columns={columns}
            dataSource={searchData.length > 0 ? searchData : data}
            pagination={{
              showSizeChanger: false,
            }}
          />
        </div>
      </div>
    </>
  );
}

export default NotificationsList;
