import { useTranslation } from "react-i18next";
import LoadingIcon from "@/features/common/loading";
import "./assetsList.css";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  GetWallet,
  selectLoadWalletData,
  selectSymbol,
  selectWalletData,
} from "./addressSlice";
import { useParams } from "react-router-dom";
import { Button, Col, Form, Input, Row, Space, Modal, Table, Tag } from "antd";
import dayjs from "dayjs";
import type { ColumnsType, TableProps } from "antd/es/table";
import { Link } from "react-router-dom";
import {
  blockchainTranslate,
  selectBlockChain,
} from "@/features/address/addressSlice";
export default function AddressList() {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const { blockchain } = useParams();
  const walletList = useAppSelector(selectWalletData);
  const loading = useAppSelector(selectLoadWalletData);
  const symbol = useAppSelector(selectSymbol);
  const dispatch = useAppDispatch();
  const blockchains = useAppSelector(selectBlockChain);
  if (!walletList || !blockchains || !blockchain) return null;
  const columns: ColumnsType<any> = [
    {
      title: "Address",
      dataIndex: "hash",
      key: "hash",
      width: 300,
      fixed: true,
      render: (text, i) => {
        return (
          <Link
            style={{ color: "#0080ff" }}
            to={`/address/1/${blockchain}/${text}`}
          >
            {text}
          </Link>
        );
      },
    },
    {
      title: `Balance (${blockchains[blockchain].mainAssetSymbol})`,
      dataIndex: "balance",
      key: "balance",
      width: 100,
      fixed: true,
      render: (i) => {
        return <>{i}</>;
      },
    },
    {
      title: "Transactions",
      dataIndex: "numberOfTransactions",
      key: "numberOfTransactions",
      width: 150,
    },
    {
      title: "First Activity",
      dataIndex: "firstActivityDate",
      key: "firstActivityDate",
      width: 100,
    },
    {
      title: "Last Activity",
      dataIndex: "lastActivityDate",
      key: "lastActivityDate",
      width: 100,
    },
  ];
  const changePage = (page: number) => {
    if (!walletList) return;

    dispatch(
      GetWallet({
        blockchain: walletList.blockchain,
        chainId: walletList.chainId,
        offset: (page - 1) * 4,
        limit: 4,
      })
    );
    setPage(page - 1);
  };
  return (
    <div className="container-item assets-container">
      {loading ? (
        <div className="container-loadicon">
          <LoadingIcon size={20} color={"#4C6FFF"} />
        </div>
      ) : null}

      <div>
        <p className="assets-title">List of Addresses</p>
      </div>
      <Table
        scroll={{ x: "800" }}
        columns={columns}
        dataSource={walletList?.data}
        pagination={{
          showSizeChanger: false,
          pageSize: 4,
          total: walletList.total,
          onChange: (page: number) => changePage(page),
        }}
      />
    </div>
  );
}
