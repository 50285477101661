import { formatData } from "@/app/util";
import { request } from "@/request";
import translate from "@/translate";
import { t } from "i18next";

export interface EntityInfo {
  name: string,
  tags: Array<string>,
  type: string,
  riskAssessment: {
    hasAnonymousCoins: boolean|any,
    hasBeenHacked: boolean|any,
    isActive: boolean|any,
    isCashOnly: boolean|any,
    isP2P: boolean|any,
    noteHacks: string|any,
    isVASP:boolean|any,
    hasExoticCoins: boolean|any,
    hasLegalEntity:boolean|any,
    
  },
  policies: {
    hasAdvancedDueDiligence: boolean,
    hasComplianceOfficer: boolean,
    hasDueDiligence: boolean,
    isApplyingSanctions: boolean,
    isAskingCopyOfId: boolean,
    isAskingPersonalInformation: boolean
  }
}
export function GetEntityInfo(id: number) {
  return new Promise<EntityInfo>(async (resolve, reject) => {
    const r = await request.get<any>(`/v1/entities/${id}`)
    const tags: Array<string> = []

    r.type && tags.push(r.type)
    r.riskAssessment.score && tags.push(r.riskAssessment.score)
    r.countries && (r.countries.length >=3 ? tags.push( t("entities.moreCountries") ) : tags.push(...r.countries.map((i: string) => i )))

    const riskAssessment = r.riskAssessment
    const policies = riskAssessment.policies

    // 翻译黑客攻击详情
    let hackText = ""
    if (riskAssessment.hasBeenHacked && riskAssessment.noteHacks) {
      hackText = await translate(riskAssessment.noteHacks)
    }

    resolve({
      name: r.name,
      tags,
      type: r.type,
      riskAssessment: {
        hasAnonymousCoins: riskAssessment.hasAnonymousCoins,
        hasBeenHacked: riskAssessment.hasBeenHacked,
        isActive: riskAssessment.isActive,
        isCashOnly: riskAssessment.isCashOnly,
        isP2P: riskAssessment.isP2P,
        noteHacks: hackText,
        isVASP:riskAssessment.isVASP,
        hasExoticCoins: riskAssessment.hasExoticCoins,
        hasLegalEntity:riskAssessment.hasLegalEntity,
      },
      policies: {
        hasAdvancedDueDiligence: policies.hasAdvancedDueDiligence,
        hasComplianceOfficer: policies.hasComplianceOfficer,
        hasDueDiligence: policies.hasDueDiligence,
        isApplyingSanctions: policies.isApplyingSanctions,
        isAskingCopyOfId: policies.isAskingCopyOfId,
        isAskingPersonalInformation: policies.isAskingPersonalInformation
      }
    })
  });
}


export interface RegulationInfo {
  country: string,
  name: string,
  regulatoryStatus: string,
  statusExpiryDate: string,
  statusStartDate: string,
  supervisoryAuthority: string
}

// 类型可翻译字段
const regulationStatusTrans = [
  "Regulated",
  "Not regulated",
  "No longer exempt"
]

// 监管信息
export function GetRegulationList(id: number) {
  return new Promise<Array<RegulationInfo>>(async (resolve, reject) => {
    const r = await request.get<any>(`/v1/entities/${id}/regulationInfo`)

    if (r.error) return resolve([])
    let data = r.map((i: any) => ({
      country: i.country,
      name: i.name,
      regulatoryStatus: i.regulatoryStatus,
      statusExpiryDate: i.statusExpiryDate ? formatData(i.statusExpiryDate) : "N/A",
      statusStartDate: i.statusStartDate ? formatData(i.statusStartDate) : "N/A",
      supervisoryAuthority: i.supervisoryAuthority || "N/A"
    }))

    await Promise.all(data.map(async (i: any, k: number) => {
      // data[k].name = await translate(i.name)
      // data[k].supervisoryAuthority = await translate(i.supervisoryAuthority)
      if (!i.regulatoryStatus) {
        data[k].regulatoryStatus = "N/A"
      } else {
        if ( regulationStatusTrans.indexOf(i.regulatoryStatus) != -1 ) {
          data[k].regulatoryStatus = t("entities.regulationType." + i.regulatoryStatus)
        } else {
          data[k].regulatoryStatus = await translate(i.regulatoryStatus)
        }
      }
      
    }))

    resolve(data)
  });
}

export interface WalletsInfo {
  addressCount: number,
  blockchain: string,
  referenceAddress: string
}
export function GetWalletsList(id: number) {
  return new Promise<Array<WalletsInfo>>(async (resolve, reject) => {
    const r = await request.get<any>(`/v1/entities/${id}/wallets`)
    resolve(r.map((i: any) => ({
      addressCount: i.addressCount,
      blockchain: i.blockchain,
      referenceAddress: i.referenceAddress,
    })))
  });
}


// 钱包 - 获取地址列表
export interface AddressRes {
  hashList: Array<string>,
  total: number,
  entityId: number,
  blockchain: string,
  entityName: string,
}
export interface GetAddressParams {entityName: string, entityId: number, blockchain: string, offset: number, limit: number}
// 获取地址列表
export function GetAddressList(params: GetAddressParams) {
  return new Promise<AddressRes>(async (resolve, reject) => {
    const r = await request.get<any>(`/v1/entities/${params.entityId}/wallets/${params.blockchain}/addresses?offset=${params.offset}&limit=${params.limit}`)
    resolve({
      total: r._metadata.total,
      hashList: r.items.map((i: any) => (i.hash)),
      blockchain: params.blockchain,
      entityId: params.entityId,
      entityName: params.entityName,
    })
  });
}
