import { convertNumber, convertNumberToChinese, formatData } from "@/app/util";
import { request } from "@/request";
import Translate from "@/translate";
import translate from "@/translate";
import { t } from "i18next";

// 分析类型
export enum AnalysisType {
  Incoming = "INCOMING",
  Outgoing = "OUTGOING",
}
// 分析类型
export enum ObjectType {
  Address = "ADDRESS",
  Transaction = "TRANSACTION",
  Wallet = "WALLET",
}

export interface InfoData {
  entityId: number;
  referenceAddress: string;
  entityName: string;
  entityType: string;
  source: string;
  tags: Array<string>;
  riskAssessment: {
    score: number;
    severity: string;
  };
  numberOfAddresses: number;
  firstActivityDate: string;
  lastActivityDate: string;
}

export function GetAddressInfo(params: {
  hash: string;
  blockchain: string;
  type: string;
}) {
  return new Promise<InfoData>(async (resolve, reject) => {
    const r = await request.get<any>(
      `/v1/blockchains/${params.blockchain}/${params.type}/${params.hash}`
    );
    const entity = r.entity;
    const tags: Array<string> = [];
    if (entity) {
      // entity.type && tags.push(t("entityType." + entity.type));
      entity.type && tags.push(entity.type);
      entity.riskAssessment.score && tags.push(entity.riskAssessment.score);
      entity.countries &&
        (entity.countries.length >= 3
          ? tags.push(t("entities.moreCountries"))
          : tags.push(
              ...entity.countries.map((i: string) => i)
            ));
    }
    resolve({
      entityId: entity ? entity.id : 0,
      referenceAddress: entity ? entity.referenceAddress : "",
      entityName: entity ? entity.name : "",
      entityType: entity ? entity.type : "",
      source:
        entity && entity.source
          ? entity.source.replaceAll("Scorechain", "Internal")
          : "",
      tags,
      riskAssessment: {
        score: entity ? entity.riskAssessment.score : 0,
        severity: entity ? entity.riskAssessment.severity : "",
      },
      numberOfAddresses: r.wallet && r.wallet.numberOfAddresses,
      firstActivityDate: r.firstActivityDate
        ? formatData(r.firstActivityDate)
        : "",
      lastActivityDate: r.lastActivityDate
        ? formatData(r.lastActivityDate)
        : "",
    });
  });
}

export interface ScoringAnalysisParams {
  analysisType: string;
  objectType: string;
  objectId: string;
  blockchain: string;
  coin: string;
  // "depth": number
}

export interface Analysis {
  percentage: number;
  name: string;
  score: number;
  type: string;
  amount: string;
  amountUsd: string;
}

export interface ScoringAnalysisData {
  score: number;
  analysisType: AnalysisType | null;
  details: Array<Analysis>;
}
export function GetScoringAnalysis(field: ScoringAnalysisParams) {
  return new Promise<ScoringAnalysisData>(async (resolve, reject) => {
    try {
      const r = await request.post<any>(`/v1/scoringAnalysis`, field);
      const data =
        field.analysisType === AnalysisType.Incoming
          ? r.analysis.incoming.result
          : r.analysis.outgoing.result;
      resolve({
        score: data ? data.score : 0,
        analysisType: field.analysisType as AnalysisType,
        details:
          data && data.details
            ? data.details.map((i: any) => ({
                percentage: i.percentage,
                name: i.name,
                score: i.score,
                type: i.type,
                amount: convertNumberToChinese(i.amount),
                amountUsd: convertNumberToChinese(i.amountUsd),
              }))
            : [],
      });
    } catch (ex: any) {
      switch (ex.response.data.error) {
        case "UNPROCESSABLE_GLOBAL_SCORE":
          reject(t("address.error.UNPROCESSABLE_GLOBAL_SCORE"));
          break;
        default:
          const trans = await Translate(ex.response.data.message);
          reject(trans);
      }
    }
  });
}

// 前端显示的具体数据
export interface ChoosMetaData {
  symbol?: string;
  balance?: string;
  balanceUsd: string;
  amountReceived?: string;
  amountReceivedUsd: string;
  amountSent?: string;
  amountSentUsd: string;
  numberOfTransactions: number;
}
export interface Metadata {
  BalanceUsd: string;
  AmountReceivedUsd: string;
  AmountSentUsd: string;
  NumberOfTransactions: number;
}
export interface Coin {
  id: number;
  blockchain: string;
  chainId: string;
  icon: string;
  name: string;
  symbol: string;
  balance: string;
  balanceUsd: string;
  amountReceived: string;
  amountReceivedUsd: string;
  amountSent: string;
  amountSentUsd: string;
  numberOfTransactions: number;

  incomeScore?: number;
  calcIncomeScore?: boolean;
  spendingScore?: number;
  calcSpendingScore?: boolean;
}

export interface MetaValues {
  metadata: Metadata;
  coins: Array<Coin>;
}
export function GetMetadatas(field: {
  blockchain: string;
  address: string;
  type: string;
}) {
  return new Promise<MetaValues>(async (resolve, reject) => {
    const r = await request.get<any>(
      `/v1/blockchains/${field.blockchain}/${field.type}/${field.address}/coins`
    );
    const metadata = r._metadata;

    let coins: Array<Coin> = [];
    for (let i = 0; i < r.items.length; i++) {
      coins.push({
        ...r.items[i].coin,
        balance: convertNumberToChinese(r.items[i].balance),
        balanceUsd: convertNumberToChinese(r.items[i].balanceUsd),
        amountReceived: convertNumberToChinese(r.items[i].amountReceived),
        amountReceivedUsd: convertNumberToChinese(r.items[i].amountReceivedUsd),
        amountSent: convertNumberToChinese(r.items[i].amountSent),
        amountSentUsd: convertNumberToChinese(r.items[i].amountSentUsd),
        numberOfTransactions: r.items[i].numberOfTransactions,
      });
    }

    resolve({
      metadata: {
        BalanceUsd: convertNumberToChinese(metadata.totalBalanceUsd),
        AmountReceivedUsd: convertNumberToChinese(
          metadata.totalAmountReceivedUsd
        ),
        AmountSentUsd: convertNumberToChinese(metadata.totalAmountSentUsd),
        NumberOfTransactions: metadata.totalNumberOfTransactions,
      },
      coins,
    });
  });
}

export interface Transaction {
  date: string;
  hash: string;
  assetName: Array<string>;
  assetIcon: Array<string>;
  amount: Array<string>;
  amountUsd: Array<string>;
  type: Array<string>;
  symbol: Array<string>;
}
export interface TransactionRes {
  data: Array<Transaction>;
  total: number;
}

// 可进行翻译转换的类型
const translateType = ["VALUE_TRANSFER"];

export function GetTransactions(field: {
  blockchain: string;
  type: string;
  address: string;
  offset: number;
  limit: number;
}) {
  return new Promise<TransactionRes>(async (resolve, reject) => {
    try {
      const r = await request.get<any>(
        `/v1/blockchains/${field.blockchain}/${field.type}/${field.address}/transactions?offset=${field.offset}&limit=${field.limit}`
      );
      let data = r.items.map((i: any) => ({
        date: formatData(i.date),
        hash: i.hash,
        assetName: i.operations.map((o: any) => o.coin.name),
        assetIcon: i.operations.map((o: any) => o.coin.icon),
        amount: i.operations.map((o: any) => convertNumberToChinese(o.amount)),
        amountUsd: i.operations.map((o: any) =>
          convertNumberToChinese(o.amountUsd)
        ),
        type: i.operations.map((o: any) => o.type),
        symbol: i.operations.map((o: any) => o.coin.symbol),
      }));

      await Promise.all(
        data.map(async (dataI: any, dataK: number) => {
          const typelist = dataI.type;
          for (let i = 0; i < typelist.length; i++) {
            // 拼接单位
            data[dataK].amount[i] =
              data[dataK].amount[i] + " " + data[dataK].symbol[i];

            if (translateType.indexOf(typelist[i]) !== -1) {
              data[dataK].type[i] =typelist[i];
              continue;
            }
            const content = await translate(typelist[i]);
            data[dataK].type[i] = content;
          }
        })
      );

      // translate(i.operations[0].type)

      resolve({
        data,
        total: r._metadata.total,
      });
    } catch (ex) {
      reject();
    }
  });
}

export interface Wallet {
  balance: string;
  firstActivityDate: string;
  hash: string;
  lastActivityDate: string;
  numberOfTransactions: number;
}

export interface WalletRes {
  data: Array<Wallet>;
  total: number;
  blockchain: string;
  chainId: string;
}
// 获取钱包列表
export function GetWallets(field: {
  blockchain: string;
  chainId: string;
  offset: number;
  limit: number;
}) {
  return new Promise<WalletRes>(async (resolve, reject) => {
    const r = await request.get<any>(
      `/v1/blockchains/${field.blockchain}/wallets/${field.chainId}/addresses?offset=${field.offset}&limit=${field.limit}`
    );
    resolve({
      data: r.items.map((i: any) => ({
        balance: convertNumberToChinese(i.balance),
        firstActivityDate: formatData(i.firstActivityDate),
        hash: i.hash,
        lastActivityDate: formatData(i.lastActivityDate),
        numberOfTransactions: i.numberOfTransactions,
      })),
      total: r._metadata.total,
      blockchain: field.blockchain,
      chainId: field.chainId,
    });
  });
}

export interface BlockChain {
  icon: string;
  id: string;
  mainAssetSymbol: string;
  name: string;
}
export interface BlockChains {
  [propName: string]: BlockChain;
}

export function GetBlockChainData() {
  return new Promise<BlockChains>(async (resolve, reject) => {
    const r: any = await request.get("/v1/blockchains");
    const blockchainMap: BlockChains = {};
    for (let i = 0; i < r.length; i++) {
      blockchainMap[r[i].id] = r[i];
    }
    resolve(blockchainMap);
  });
}

export interface WalletData {
  data: Array<any>;
}