import { Login } from '@/features/user/Login';
import LoginAside from '@/assets/loginAsidePic.svg'
import LoginAsideIcon from '@/assets/loginAsideIcon.png'
import './Login.css';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { initUserConfig, resetUserPageStatus, selectInited } from '@/features/user/userSlice';

function LoginPage() {
  const inited = useAppSelector(selectInited)
  const dispatch = useAppDispatch()
  
  useEffect(() => {
    if (!inited ) {
      dispatch(initUserConfig())
    }
    dispatch(resetUserPageStatus())
  })
  
  document.title = "Falconchain Check Login"
  
  return (
    <div className="login-container">
      <aside>
        <img className='aside' src={LoginAside} alt="" />
        <img className='aside-icon' src={LoginAsideIcon} alt="" />
      </aside>
      <div className='form-wrap'>
        <Login />
      </div>
    </div>
  );
}

export default LoginPage;
