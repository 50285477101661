import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectLoading,
  login,
  selectLogin,
  selectAvatar,
  selectError,
  selectToken,
  emptyLoginError,
} from "./userSlice";

import { useTranslation } from "react-i18next";
import { setUserInfo } from "./Auth";
import LoadingIcon from "@/features/common/loading";

import styles from "./user.module.css";
import { GetNotification } from "../alert/alertSlice";
import { useLocation } from "react-router";

export function Login() {
  const { t } = useTranslation();

  const loading = useAppSelector(selectLoading);
  const logined = useAppSelector(selectLogin);
  const avatar = useAppSelector(selectAvatar);
  const errors = useAppSelector(selectError);
  const token = useAppSelector(selectToken);
  const dispatch = useAppDispatch();

  const [caseName, setCaseName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const aLocation = useLocation();

  useEffect(() => {
    if (logined) {
      dispatch(setUserInfo({ email, avatar, token, caseId: caseName }));
      dispatch(GetNotification({ offset: 0, limit: 1 }));
      setTimeout(() => {
        const params: any = {};
        aLocation.search
          .slice(1)
          .split("&")
          .forEach((i) => {
            const v = i.split("=");
            params[v[0]] = v[1];
          });

        if ("redirect" in params) {
          window.location.href = params["redirect"];
        } else {
          navigate(`/`);
        }
      });
    }
    // eslint-disable-next-line
  }, [logined]);

  return (
    <div className={styles.wrap}>
      <p className={styles.title}>{t("login.title")}</p>
      <div>
        <p
          className={
            styles[
              "login-field-text-" +
                (errors.caseError !== "" ? "error" : "normal")
            ]
          }
        >
          {t("login.text.caseNumber")}
        </p>
        <input
          className={
            styles["login-input" + (errors.caseError !== "" ? "-error" : "")]
          }
          type="text"
          value={caseName}
          // value="Urvh8Xwj4SHn"
          onChange={(e) => {
            setCaseName(e.target.value);
            dispatch(emptyLoginError());
          }}
          placeholder={t("login.ignore.caseNumber")}
        />
        <span className={styles["login-error-text"]}>{errors.caseError}</span>

        <p
          className={
            styles[
              "login-field-text-" +
                (errors.emailError !== "" ? "error" : "normal")
            ]
          }
        >
          {t("login.text.email")}
        </p>
        <input
          className={
            styles["login-input" + (errors.emailError !== "" ? "-error" : "")]
          }
          type="text"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            dispatch(emptyLoginError());
          }}
          placeholder={t("login.ignore.email")}
        />
        <span className={styles["login-error-text"]}>{errors.emailError}</span>

        <p
          className={
            styles[
              "login-field-text-" +
                (errors.passwordError !== "" ? "error" : "normal")
            ]
          }
        >
          {t("login.text.password")}
        </p>
        <input
          className={
            styles[
              "login-input" + (errors.passwordError !== "" ? "-error" : "")
            ]
          }
          type="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            dispatch(emptyLoginError());
          }}
          placeholder={t("login.ignore.password")}
        />
        <span className={styles["login-error-text"]}>
          {errors.passwordError}
        </span>

        <p className={styles["forgot-block"]}>
          {/* <span onClick={() => {
            navigate(`/register`)
          }}>{t("login.button.register")}</span> */}

          <span
            onClick={() => {
              navigate(`/forgetPass`);
            }}
          >
            {t("login.button.forgot")}
          </span>
        </p>
        <div className={styles["login-other-error"]}>{errors.otherError}</div>
        <div
          className={styles["login-button" + (loading ? "-loading" : "")]}
          onClick={() => {
            if (!loading) dispatch(login({ email, password, caseName }));
          }}
        >
          {loading ? <LoadingIcon size={20} color={"#fff"} /> : null}
          <span>{t("login.button.login")}</span>
        </div>

        <p className={styles["agreement"]}>
          By logging in, you acknowledge that you have read and agreed to the
          'User Agreement' and 'Privacy Policy'.
        </p>
      </div>
    </div>
  );
}
