import Header from '@/features/user/Header'
import Navigation from '@/features/common/navigation'

import Topic from '@/features/common/topicInfo'
import RiskScore from '@/features/address/riskScore'
import AssetsList from '@/features/address/tradingList'
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { initTransaction, selectLoading, selectTransaction } from "@/features/transaction/transactionSlice";
import { GetAnalysisData, selectAnalysisType, selectBlockChain } from "@/features/address/addressSlice";
import { AnalysisType, ObjectType } from "@/features/address/addressAPI";
import { useEffect } from "react";

export default function Trading() {

  const { blockchain, hash } = useParams();
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const transaction = useAppSelector(selectTransaction);
  const analysisType = useAppSelector(selectAnalysisType);
  const blockchains = useAppSelector(selectBlockChain);
  const loading = useAppSelector(selectLoading)

  useEffect(() => {
    if (!loading && (blockchain && hash) ) {
      dispatch(initTransaction({
        blockchain,
        hash
      }))
    }
  })

  if (!blockchain || !hash ) return navigate("/")
  document.title = `Transaction ${hash}`

  const changeAnalyType = (type: AnalysisType) => {
    dispatch(GetAnalysisData({
      analysisType: type,
      objectType: ObjectType.Transaction,
      objectId: hash,
      blockchain: blockchain
    }))
  }

  const ChangeRiskSelect = (coin: string) => {
    dispatch(GetAnalysisData({
      analysisType: analysisType,
      objectType: ObjectType.Transaction,
      objectId: hash,
      blockchain: blockchain,
      coin
    }))
  }
  return (
    <>
      <Header />
      <div className="body-container">
        <Navigation id={hash} />

        <Topic
          title={`${blockchain} ${t("transaction.title")} Information`}
          icon={blockchains ? `https://app.scorechain.com/images/cryptoicons/${blockchains[blockchain].icon}` : ""}
          name={hash}
          details={transaction ? `Transaction Date：${transaction.transactionDate}` : ""}
          blockDetail={transaction ? `Block：${transaction.block}（${transaction.numberOfConfirmations}${t("transaction.confirmText")}）` : ""}
          // firstActive={"2022-10-11 18:20"}
          // lastActive={"2022-11-11 18:20"}
          />

        <RiskScore symbol={transaction?.operations[0].symbol || ""} showAssets={false}  onChangeType={changeAnalyType} onChangeSelect={ChangeRiskSelect} />

        <AssetsList />

      </div>
    </>
  );
}
